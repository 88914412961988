* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  -webkit-text-size-adjust: 100%;
}

body {
  -webkit-font-smoothing: antialiased;
  font-family: 'Montserrat', sans-serif;
}

h3 {
  margin: 0;
}

.error-input {
  border: none;
  border: 1px solid red !important;
  border-radius: 4px;
}

.error-div {
  color: #f44336;
  margin-top: -10px;
  margin-bottom: 8px;
  font-size: 12px;
  text-align: left;
}

.date-helper-text {
  margin: 0px !important;
  padding-top: 3px !important;
  box-shadow: none !important;
  animation: slowanimate 0.5s ease both;
}

#pin-helper-text {
  animation: slowanimate 0.5s ease both;
}

/* animation for error .date-helper-text*/
@keyframes slowanimate {
  from {
    opacity: 0;
    transform: translateY(-5px);
  }
  to {
    opacity: 1;
    transform: translateY(1px);
  }
}
